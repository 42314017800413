import React, { useEffect, useState } from "react";
import Images from '../../common/Index';
import * as Api from "../../../Apis";
import { NavLink } from "react-router-dom";
import _ from "underscore";
import { encryption } from '../../../modules/module';
import LinearProgress from "../../common/LinearProgress";
import { useMediaQuery } from "@uidotdev/usehooks";

const categoryList = [
    { label: "Animals & Nature", value: "animals_nature" },
    { label: "Art & Culture", value: "art_culture" },
    { label: "Cars & Bikes", value: "cars_bikes" },
    { label: "Photography", value: "photography" },
    { label: "Education", value: "education" },
    { label: "Electronics", value: "electronics" },
    { label: "Entertainment", value: "entertainment" },
    { label: "Fashion", value: "fashion" },
    { label: "Food & Restaurants", value: "food_restaurant" },
    { label: "Gifts", value: "gifts" },
    { label: "Health & Beauty", value: "health_beauty" },
    { label: "Sports", value: "sports" },
    { label: "Technology", value: "technology" },
    { label: "Default", value: "default" },
]
// const themeList = [
//     { label: "Theme 1", value: "theme1" },
//     { label: "Theme 2", value: "theme2" },
//     { label: "Theme 3", value: "theme3" },
// ]

export default function CreateTheme() {
    const [preview, setPreview] = useState("")
    const [imageURL, setimageURL] = useState("")
    const [addNew, setaddNew] = useState(false)
    const [loading, setloading] = useState(true)
    const [errors, setErrors] = useState(false)
    const [value, setValue] = useState({})
    const [themeList, setThemeList] = useState({})
    const [activeCategory, setActiveCategory] = useState("")
    const [activeTheme, setActiveTheme] = useState("")
    const [sizeError, setSizeError] = useState(false)
    const logout = () => {
        localStorage.removeItem("admin_token")
        navigate("/admin/login")
    }

    const getList = () => {
        Api.GetApiAdmin("theme").then(async res => {
            console.log("theme res", res)
            setloading(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    setThemeList(res.data.data)
                }
            }
        })
    }

    const handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log("file: ", file)
        if (file && file.size <= 20000000) {
            reader.onloadend = () => {
                setPreview(reader.result)
                if (file !== null) {
                    let data = new FormData();
                    // data.append("image", selectedFile)
                    data.append("templates", file)

                    Api.PostApiAdminFormdata("theme/image", data).then(async res => {
                        console.log("theme/image: ", res)
                        if (res.error === true) {
                            Api.Alert(res.response.data.error, "error")
                        } else {
                            if (res.data.data) {
                                setimageURL(res.data.data)
                                setValue({ ...value, "image": res.data.data })
                            }
                        }
                    })
                }
            }
            reader.readAsDataURL(file)
            setSizeError(false)
            e.target.value = ""
        } else {
            setSizeError(true)
        }
    }


    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const handleThemeCategory = (e) => {
        setActiveCategory(e.target.value)
        setValue({})
        setActiveTheme(themeList.themes[e.target.value][0])
    }
    const handleThemeSelect = (e) => {
        if (e.target.value) {
            let active_theme = JSON.parse(e.target.value)
            setActiveTheme(active_theme)
        }
    }

    useEffect(() => {
        if (activeTheme) {
            setimageURL(activeTheme?.image)
            setValue({
                ...value,
                image: activeTheme?.image,
                theme_category: activeTheme?.theme_category,
                theme_name: activeTheme?.theme_name,
                theme_preview_url: activeTheme?.theme_preview_url,
                theme_json: JSON.stringify(activeTheme?.theme_json),
                default_theme_json: JSON.stringify(activeTheme?.default_theme_json),
            })
        }
    }, [activeTheme])

    const handleCancel = () => {
        setaddNew(false)
        setActiveTheme("")
        setPreview("")
        setValue({})
    }

    const saveFunction = (url, api, data) => {
        api(`theme${url}`, data).then(async res => {
            console.log("theme submit res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    Api.Alert(res.data.data, "success")
                    getList()
                    handleCancel()
                }
            }
        })
    }
    const handleSubmit = () => {
        if (_.isEmpty(value) || (!value?.theme_category || !value?.image || !value?.theme_json || !value?.theme_name || !value?.theme_preview_url)) {
            setErrors(true)
        } else {
            setErrors(false)
            let data = {
                ...value,
                "is_default": false,
                "is_active": true,
                "theme_color": "#fff",
            }
            if (data?.theme_json) {
                data["theme_json"] = JSON.parse(data?.theme_json)
            }
            if (data?.default_theme_json) {
                data["default_theme_json"] = JSON.parse(data?.default_theme_json)
            }
            console.log("data:L ", data)
            if (activeTheme) {
                saveFunction(`/${activeTheme?.theme_id}`, Api.PutApiAdmin, data)
            } else {
                saveFunction("", Api.PostApiAdmin, data)
            }
        }
    }

    const addNewButton = () => {
        setaddNew(true)
        setActiveTheme("")
        setPreview("")
        setValue({})
        setimageURL("")
    }

    useEffect(() => {
        getList()
    }, [])


    console.log("themeList", themeList)
    console.log("activeTheme", activeTheme)
    console.log("value", value)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    return (
        <div className='loginContainer justify-content-start py-3 networkPage'>
            <div className="container">
                <div className="metworkHeader ">
                    <div className="d-flex justify-content-between align-items-start">
                        <div className="loginLogo">
                            <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                        </div>
                        <div className='admin_header'>
                            <NavLink to="/admin/users" className="btn btn-text-primary border-0 p-0 me-4">Users</NavLink>
                            <NavLink to="/admin/stores" className="btn btn-text-primary border-0 p-0 me-4">Stores</NavLink>
                            <NavLink to="/admin/themes" className="btn btn-text-primary border-0 p-0 me-4">Themes</NavLink>
                            <button className="btn btn-outline-danger btn-sm" onClick={logout}>Logout</button>
                        </div>
                    </div>
                </div>
                {loading ?
                    <LinearProgress />
                    :
                    <div className="">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group mt-1 mb-3'>
                                            <div>
                                                <div className='form-select-outer'>
                                                    <select name="theme_category" className='form-control w-100' onChange={handleThemeCategory} value={activeCategory}>
                                                        <option value="" disabled selected>Choose category</option>
                                                        {themeList.categories && themeList.categories.length > 0 && themeList.categories.map((opt, i) => {
                                                            return (
                                                                <option value={opt} key={i}>{opt}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {activeCategory !== "" && themeList.themes && !_.isEmpty(themeList.themes) && themeList.themes[activeCategory].length > 0 ?
                                            <div className='form-group mt-1 mb-3'>
                                                <div>
                                                    <div className='form-select-outer'>
                                                        <select name="theme_category" className='form-control w-100' onChange={handleThemeSelect} value={activeTheme}>
                                                            <option value="" disabled selected>Choose theme</option>
                                                            {themeList.themes[activeCategory].map((opt, i) => {
                                                                return (
                                                                    <option value={JSON.stringify(opt)} key={i}>{opt?.theme_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 text-end">
                                <button className="btn btn-primary" onClick={addNewButton}>Add new</button>
                            </div>
                        </div>

                        {activeTheme || addNew ?
                            <>
                                <hr />
                                <div className='row flex-row-reverse'>
                                    <div className='col-md-9'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Theme name</label>
                                                <div className='form-group mt-1 mb-3'>
                                                    <div>
                                                        <input type="text" className='form-control' name="theme_name" value={value?.theme_name || ""} onChange={handleChange} />
                                                    </div>
                                                    {errors && !value?.theme_name ?
                                                        <p className='mb-0 error'><small>Please enter theme name</small></p>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Category</label>
                                                <div className='form-group mt-1 mb-3'>
                                                    <div>
                                                        <div className='form-select-outer'>
                                                            <select name="theme_category" className='form-control w-100' onChange={handleChange} value={value?.theme_category || ""}>
                                                                <option value="" disabled selected>Choose category</option>
                                                                {categoryList.map((opt, i) => {
                                                                    return (
                                                                        <option value={opt?.label} key={i}>{opt?.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errors && !value?.theme_category ?
                                                        <p className='mb-0 error'><small>Please select theme category</small></p>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Preview url</label>
                                                <div className='form-group mt-1 mb-3'>
                                                    <div>
                                                        <input type="text" className='form-control' name="theme_preview_url" value={value?.theme_preview_url || ""} onChange={handleChange} />
                                                    </div>
                                                    {errors && !value?.theme_preview_url ?
                                                        <p className='mb-0 error'><small>Please enter theme preview url</small></p>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Image url</label>
                                                <div className='form-group mt-1 mb-3'>
                                                    <div>
                                                        <input type="text" className='form-control' name="image" value={value?.image || imageURL} onChange={handleChange} />
                                                    </div>
                                                    {errors && (!value?.image || !imageURL) ?
                                                        <p className='mb-0 error'><small>Please enter theme image url</small></p>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Preview theme JSON (Default settings)</label>
                                                <div className='form-group mt-1 mb-3'>
                                                    <div>
                                                        <textarea className='form-control' name="theme_json" rows={12} style={{ height: "auto" }} value={value?.theme_json || ""} onChange={handleChange} />
                                                    </div>
                                                    {errors && !value?.theme_json ?
                                                        <p className='mb-0 error'><small>Please enter theme JSON</small></p>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Store demo theme JSON (Theme's actual view)</label>
                                                <div className='form-group mt-1 mb-3'>
                                                    <div>
                                                        <textarea className='form-control' name="default_theme_json" rows={12} style={{ height: "auto" }} value={value?.default_theme_json || ""} onChange={handleChange} />
                                                    </div>
                                                    {errors && !value?.default_theme_json ?
                                                        <p className='mb-0 error'><small>Please enter default theme JSON</small></p>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className="mt-5">
                                                    <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
                                                    <button className="btn btn-outline-danger ms-3" onClick={handleCancel}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>Theme Image</label>
                                        <div className='form-group mt-1 mb-3'>
                                            <div>
                                                <div>
                                                    <input type="file" className='form-control' style={{ height: "auto" }} name="image" accept="image/*" id="themeImage" onChange={handleImageChange} />
                                                </div>
                                                <p className='mb-0 error'><small>{sizeError ? "Max file size is 20MB" : ""}</small></p>
                                                {imageURL !== "" ?
                                                    <p className="mt-2">{imageURL}</p>
                                                    : null
                                                }
                                                {preview !== "" || value?.image !== "" ?
                                                    <div className="preview_img mt-3">
                                                        <img src={preview || value?.image} alt="" className="img-fluid" />
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                            : null
                        }
                    </div>
                }
            </div>
        </div >
    )
}