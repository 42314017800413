import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css'
import "bootstrap/dist/css/bootstrap.css";
import './App.css';
import Login from './components/Auth/Login';
import Home from './components/Home';
import Register from './components/Auth/Register';
import StoreLogin from './components/Store/StoreLogin';
import PageNotFound from './PageNotFound';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';

import { PrivateRoute, PrivateRouteSuport, PublicRoute, PublicRouteSupport,PublicRouteNetwork, PrivateRouteNetwork, PublicRouteAdmin, PrivateRouteAdmin } from './Apis';
import Verify from './components/Auth/Verify';
import SupportLogin from './components/Store/Support/SupportLogin';
import Support from './components/Store/Support';
import SupportConnect from './components/Store/Support/SupportConnect';
import Interceptor from './modules/interceptor';
import Network from './components/Network/Network';
import NetwokLogin from './components/Network/Login';
import AdminLogin from './components/AdminArea/Login';
import AdminArea from './components/AdminArea/Users';
import UserDetail from './components/AdminArea/Users/UserDetail';
import CreateTheme from './components/AdminArea/Themes';
import Token from './components/Auth/Token';
Interceptor()
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/logout' element={<Home />} />
          <Route exact path='/verify' element={<Verify />} />
          <Route exact path='/verify/store' element={<Verify />} />
          <Route exact path='/token' element={<Token />} />

          <Route exact path='/' element={<PublicRoute />}>
            <Route exact path='/' element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            {/* <Route exact path='/support/login' element={<SupportLogin />} /> */}
            <Route exact path='/signup' element={<Register />} />
            <Route exact path='/forgot-password' element={<ForgotPassword />} />
            <Route exact path='/forgot' element={<ResetPassword />} />
          </Route>
          <Route exact path='/' element={<PrivateRoute />}>
            <Route exact path='/store-login' element={<StoreLogin />} />
          </Route>

          <Route exact path='/' element={<PublicRouteSupport />}>
            <Route exact path='/support/login' element={<SupportLogin />} />
          </Route>
          <Route exact path='/' element={<PrivateRouteSuport />}>
            <Route exact path='/support/connect' element={<SupportConnect />} />
            <Route exact path='/support' element={<Support />} />
          </Route>


          <Route exact path='/' element={<PublicRouteNetwork />}>
            <Route exact path='/network/login' element={<NetwokLogin />} />
          </Route>
          <Route exact path='/' element={<PrivateRouteNetwork />}>
            <Route exact path='/network/listing' element={<Network />} />
          </Route>
          <Route exact path='/' element={<PublicRouteAdmin />}>
            <Route exact path='/admin/login' element={<AdminLogin />} />
          </Route>
          <Route exact path='/' element={<PrivateRouteAdmin />}>
            <Route exact path='/admin/users' element={<AdminArea />} />
            <Route exact path='/admin/users/:id' element={<UserDetail type="user" />} />
            <Route exact path='/admin/stores' element={<UserDetail type="store" />} />
            <Route exact path='/admin/themes' element={<CreateTheme />} />
          </Route>



          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
